import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PetSittingTypes } from 'src/app/shared/guards/pet-sitting-type.guard';
import { AppState } from 'src/stores';
import { PetSitterState } from 'src/stores/pet-sitter/pet-sitter.state';
import { RouterSelectors } from 'src/stores/router/router.selectors';

const selectPetSitterState = createFeatureSelector<AppState, PetSitterState>('petSitter');

/**
 * Profile
 */
const selectProfile = createSelector(selectPetSitterState, ({ profile }) => profile);
const selectProfileStatus = createSelector(selectPetSitterState, ({ profile }) =>
    profile ? profile.status : null,
);
const PROFILE_SELECTORS = { selectProfile, selectProfileStatus };

/**
 * Services
 */
const selectServices = createSelector(selectPetSitterState, ({ services }) => services);
const selectService = createSelector(
    selectServices,
    RouterSelectors.selectRouteParam('type'),
    (services, type) => {
        if (!services || !type) {
            return null;
        }
        switch (type) {
            case PetSittingTypes.ACCOMODATION:
                return services.accommodation;
            case PetSittingTypes.HOME_CARE:
                return services.homeCare;
            case PetSittingTypes.NURSERY:
                return services.nursery;
            case PetSittingTypes.WALK:
                return services.walk;
        }
    },
);
const SERVICES_SELECTORS = { selectServices, selectService };

/**
 * Availabilities
 */
const selectAvailabilities = createSelector(selectPetSitterState, ({ availabilities }) =>
    availabilities ? availabilities.dates : null,
);
const AVAILABILITIES_SELECTORS = { selectAvailabilities };

/**
 * Reviews
 */
const selectReviews = createSelector(selectPetSitterState, ({ reviews }) => reviews);
const selectReviewsBadge = createSelector(selectReviews, (reviews) => {
    return (
        reviews &&
        reviews.reduce((acc, current) => {
            return acc + (current.seen ? 0 : 1);
        }, 0)
    );
});
const REVIEWS_SELECTORS = { selectReviews, selectReviewsBadge };

export const PetSitterSelectors = {
    ...PROFILE_SELECTORS,
    ...SERVICES_SELECTORS,
    ...AVAILABILITIES_SELECTORS,
    ...REVIEWS_SELECTORS,
};
